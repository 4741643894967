import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import useMediaQuery from "@material-ui/core/useMediaQuery";

// material-ui core components
import { List, ListItem, Grid, Box, Link, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import CTABlock from "components/Content/CTABlock";

// @material-ui/icons
import { Copyright, Favorite } from "@material-ui/icons";

import customStyles from "assets/jss/custom/customStyles.js";

const useStyles = makeStyles({
  ...customStyles,
});

const items = [
  { label: "AGB", href: "/agb" },
  { label: "Datenschutz", href: "/datenschutz" },
  { label: "Kontakt", href: "/kontakt" },
  { label: "Impressum", href: "/impressum" },
];

export default function Footer(props) {
  const theme = useTheme();
  const isAboveSm = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const { whiteFont, withSignup = true } = props;

  return (
    <div>
      {withSignup && (
        <div
          className={classNames(
            classes.rowOdd,
            classes.hasBlurBackground,
            classes.isWhite
          )}
        >
          <CTABlock />
        </div>
      )}
      <footer className={classes.footer}>
        <div className={classes.container}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              className={isAboveSm ? "" : classes.hasTextCentered}
            >
              <Grid
                container
                justifyContent="center"
                spacing={isAboveSm ? 3 : 1}
                direction="row"
              >
                {items.map((item) => (
                  <Grid item justifyContent="center">
                    <Link href={item.href} className={classes.footerLink}>
                      {item.label}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.rowOdd}>
          <Grid
            container
            className={classes.container}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Box
                display="flex"
                justifyContent={isAboveSm ? "flex-end" : "center"}
                alignItems="center"
                style={{ marginTop: "0.25em", marginBottom: "0.75em" }}
              >
                <Copyright
                  fontSize="inherit"
                  color="inherit"
                  style={{
                    marginBottom: "0.1em",
                    marginRight: "0.25em",
                  }}
                />
                {new Date().getFullYear()} getasugar.ch - Sugardaddy Portal für die Schweiz
              </Box>
            </Grid>

            {/*
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                justifyContent={isAboveSm ? "flex-end" : "center"}
                style={{ gap: "1em" }}
              >
                <FacebookIcon />
                <TwitterIcon />
                <YoutubeIcon />
                <InstagramIcon />
              </Box>
            </Grid>
            */}
          </Grid>
        </div>
      </footer>
    </div>
  );
}
